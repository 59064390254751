import moment from 'moment';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import IconArrow from './img/arrow-slider.svg';
import IconMoon from './img/moon.png'

const generateDates = (days = 10) => {
	const dates = [];

	let date = moment();
	date.hours(12);
	date.minutes(0);
	date.seconds(0);
	date.milliseconds(0);

	while (days > 0) {
		dates.push({
			month: date.format('MMMM'),
			date: date.format('DD'),
			day: date.format('dddd'),
			iso: date.toISOString(),
		});

		date.add(1, 'day');
		days -= 1;
	}

	return dates;
};

const DATES = generateDates(40);

const isOvernightAvailable = (day, availability) => {
	const daysOfWeek = Object.keys(availability);
	const dayIndex = daysOfWeek.indexOf(day);
	if (dayIndex === -1) return false;

	const prevDay = daysOfWeek[dayIndex - 1] || daysOfWeek[daysOfWeek.length - 1];
	const nextDay = daysOfWeek[dayIndex + 1] || daysOfWeek[0];
	const prevDaySlots = availability[prevDay] || [];
	const currentDaySlots = availability[day] || [];
	const nextDaySlots = availability[nextDay] || [];

	const prevDayEndsAtMidnight = prevDaySlots.some(slot => slot.endTime === '23:59');
	const currentDayStartsAtMidnight = currentDaySlots.some(slot => slot.startTime === '00:00');
	const currentDayEndsAtMidnight = currentDaySlots.some(slot => slot.endTime === '23:59');
	const nextDayStartsAtMidnight = nextDaySlots.some(slot => slot.startTime === '00:00');

	return (prevDayEndsAtMidnight && currentDayStartsAtMidnight) || (currentDayEndsAtMidnight && nextDayStartsAtMidnight);
};

const DatesSlider = ({ onChange, selected = '', availability, overnight }) => {
	const [page, setPage] = useState(0);
	const [maxPages, setMaxPages] = useState(0);

	useEffect(() => {
		onChange('')
	}, [overnight])

	useEffect(() => {
		const max = Math.floor(DATES.length / 5) - 1;
		setMaxPages(max);
	}, []);

	const changePage = useCallback(
		(val) => {
			let newPage = page + val;
			if (newPage < 0) newPage = 0;
			if (newPage > maxPages) newPage = maxPages;
			setPage(newPage);
		},
		[page, maxPages]
	);

	useEffect(() => {
		const idx = DATES.findIndex((d) => d.iso === selected);
		const newPage = Math.floor(idx / 5);

		if (idx !== -1 && newPage !== page) {
			setPage(newPage);
		}
	}, [selected, page]);

	const canLeft = useMemo(() => page > 0, [page]);
	const canRight = useMemo(() => page < maxPages, [page, maxPages]);

	const handleDateClick = (iso) => {
		if (overnight) {
			if (selected.length === 0) {
				onChange([iso]);
			} else if (selected.length === 1) {

				const firstDate = moment(selected[0]);
				const secondDate = moment(iso);
				const diff = Math.abs(firstDate.diff(secondDate, 'days'));

				if (diff === 1) {
					onChange([selected[0], iso]);
				} else {
					onChange([iso]);
				}
			} else {
				onChange([iso]);
			}
		} else {
			onChange([iso]);
		}
	};

	return (
		<div className="dates-slider">
			<div className="overflow">
				<div className="container" style={{ transform: `translateX(-${page * 100}%)` }}>
					{DATES.map(({ month, date, day, iso }, index) => {
						const isOvernight = isOvernightAvailable(day, availability);
						const isDisabled = overnight ? !isOvernight : (availability[day] && availability[day]?.length === 0);
						const isSelected = selected.includes(iso);

						return (
							<div
								className={classNames('item', { selected: isSelected, disabled: isDisabled })}
								onClick={() => !isDisabled && handleDateClick(iso)}
								key={index}
							>
								{(overnight && !isDisabled) && <span><img src={IconMoon} alt="moon_icon" /></span>}
								<div className="month">{month} </div>
								<div className="date">{date} </div>
								<div className="day">{day}</div>
							</div>
						);
					})}
				</div>
			</div>

			<img
				src={IconArrow}
				className={classNames('arrow left', { disabled: !canLeft })}
				alt="Move left"
				onClick={() => changePage(-1)}
			/>

			<img
				src={IconArrow}
				className={classNames('arrow right', { disabled: !canRight })}
				alt="Move right"
				onClick={() => changePage(1)}
			/>
		</div>
	);
};

export default DatesSlider;
