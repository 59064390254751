import axios from 'axios';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useStore } from '../../store';

import ConfirmationCode from 'components/ConfirmationCode/ConfirmationCode';
import GoogleSign from 'components/GoogleSign/GoogleSign';
import Loader from 'components/Base/Loader';

import IconClose from './close.svg';
import IconArrow from './cat-arrow-down.svg';
import Dropdown from 'components/Base/Dropdown';
import PhoneInput from 'react-phone-input-2';
import { countdown, parseError } from 'utils/helpers';

const Signin = () => {
	const [userType, setUserType] = useState('customer');
	const [isLoading, setIsLoading] = useState(false);
	const [modalState, setModalState] = useState("login");
	const [resetStep, setResetStep] = useState(0);
	const [resetCode, setResetCode] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState({});
	const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneCode, setPhoneCode] = useState('');
	const [timeLeft, setTimeLeft] = useState(0);
	const [isOTPSend, setIsOTPSend] = useState(false);

	const store = useStore();
	const show = store.auth.showModal;
	const userTypeMapping = {
		Customer: 'customer',
		Caregiver: 'provider'
	};

	useEffect(() => {
		if (show) {
			setEmail('');
			setPassword('');
			setModalState("login");
		}
	}, [show]);

	const hideModal = useCallback(() => {
		store.auth.toggleModal(false);
	}, [store.auth]);

	const overlayClose = useCallback(
		(e) => {
			if (e.target.classList.contains('overlay')) {
				hideModal();
			}
		},
		[hideModal]
	);

	const signIn = useCallback(() => {
		axios
			.post(`/auth/signin/${userType}`, { email, password })
			.then(({ data }) => {
				store.auth.authorize(data.token, userType, data.backgroundCheckResult);
			})
			.catch((e) => {
				setError(parseError(e));
			});
	}, [email, password, userType, store.auth]);

	const startReset = useCallback(() => {
		setModalState("reset");
		setResetStep(0);
		setResetCode('');
	}, []);

	const resetPassword = useCallback(() => {
		setIsLoading(true);
		setError({});

		if (resetStep === 0) {
			axios
				.post(`/auth/reset/${userType}`, { email })
				.then(({ data }) => {
					store.notif.create({
						title: 'Reset started',
						content:
							'An email has been sent to you to confirm your password reset. Check your email and enter the code from the letter in the code below',
						type: 'info',
						autoClose: 15000,
					});
					setResetStep(1);
					// setIsReset(false);
				})
				.catch((e) => {
					setError(parseError(e));
				})
				.finally(() => {
					setIsLoading(false);
				});
		}

		if (resetStep === 1) {
			setResetStep(2);
			setIsLoading(false);
		}

		if (resetStep === 2) {
			axios
				.post(`/auth/reset/confirm`, {
					email,
					password,
					code: resetCode.join(''),
				})
				.then(({ data }) => {
					setResetStep(2);
					setModalState("login");

					store.notif.create({
						title: 'Success',
						content: 'Password reset successfully!',
						type: 'success',
						autoClose: 10000,
					});
				})
				.catch((e) => {
					const status = e.response?.status;
					if (status === 422) {
						setResetStep(1);
						return store.notif.create({
							title: 'Reset error',
							content: e.response.data.error,
							type: 'error',
							autoClose: 10000,
						});
					} else if (status === 426) {
						setResetStep(0);
						return store.notif.create({
							title: 'Reset error',
							content: e.response.data.error,
							type: 'error',
							autoClose: 10000,
						});
					} else {
						setError(parseError(e));
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [store.notif, email, password, userType, resetCode, resetStep]);

	const googleSignIn = useCallback(
		({ access_token }) => {
			axios
				.post(`/auth/signin/${userType}/google`, { access_token })
				.then(({ data }) => {
					store.auth.authorize(data.token, userType, data.backgroundCheckResult);
				})
				.catch((e) => {
					const message = e.response?.data?.error || 'is invalid';
					setError({ type: 'Google Auth', message });
				});
		},
		[userType, store.auth]
	);

	const numberSignIn = async () => {
		if (!phoneNumber) {
			setError({ message: "Phone number is required." });
			return;
		}
		try {
			setError({})
			await axios.post(`/auth/signin/number/${userType}`, {
				resend: isOTPSend, number: phoneNumber
			});

			setTimeLeft(60)
			countdown(setTimeLeft)
			setIsOTPSend(true)
		} catch (error) {
			setError(parseError(error));
			console.error("Failed to send OTP:", error.message);
		}
	};

	const confirmNumberSignIn = async () => {
		if (!phoneCode) {
			setError({ message: "Verification code is required." });
			return;
		}
		axios.post(`/auth/signin/number/confirm/${userType}`, { code: phoneCode, number: phoneNumber })
			.then(({ data }) => {
				store.auth.authorize(data.token, userType, data.backgroundCheckResult);
			})
			.catch((e) => {
				setError(parseError(e));
				console.error("Validation failed", e);
			});
	};

	return (
		<div className={classNames('overlay', { show })} onClick={overlayClose}>
			<div className="sign-component signin-modal">
				{modalState === "login" && (
					<div className="form">
						<h4>Sign In</h4>
						<div className="signin">
							Don’t have an account?{' '}
							<Link to="/" onClick={hideModal}>
								Sign up
							</Link>
						</div>

						<div className="line">
							<div className="field">
								<div className='label'>Are you <b>Seeking Care</b> or <b>Giving Care?</b></div>
								<Dropdown
									items={Object.keys(userTypeMapping)}
									autoclose={true}
									onChange={(val) => setUserType(userTypeMapping[val])}
								>
									{Object.keys(userTypeMapping).find(key => userTypeMapping[key] === userType)}
									<img src={IconArrow} className="arrow" alt="Arrow" />
								</Dropdown>
							</div>
						</div>

						<GoogleSign onSign={googleSignIn} type="in" />
						<div className="or">
							<span>or</span>
						</div>
						<button className='submit phone' onClick={() => {
							setModalState("phone");
						}}>
							Sign in with phone number
						</button>

						<div className="or">
							<span>or</span>
						</div>

						<input
							type="email"
							placeholder="Email"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
						/>
						<input
							type="password"
							placeholder="Password"
							onChange={(e) => setPassword(e.target.value)}
							password={password}
						/>

						<div className="error-message">
							<b>{error.type}</b>
							{error.message}
						</div>

						<button className="submit" onClick={signIn}>
							Sign In
						</button>
						<div className="reset" onClick={startReset}>
							Forgot password?
						</div>
					</div>
				)}
				{modalState === "reset" && (
					<div className="form reset">
						<h4>Reset password</h4>
						<div className="signin">
							Don’t have an account?{' '}
							<Link to={`/auth/${userType}`} onClick={hideModal}>
								Sign up
							</Link>
						</div>

						{resetStep === 0 && (
							<div className="types">
								<Dropdown
									items={Object.keys(userTypeMapping)}
									autoclose={true}
									onChange={(val) => setUserType(userTypeMapping[val])}
								>
									{Object.keys(userTypeMapping).find(key => userTypeMapping[key] === userType)}
									<img src={IconArrow} className="arrow" alt="Arrow" />
								</Dropdown>
							</div>
						)}

						{resetStep === 0 && (
							<input
								type="email"
								placeholder="Email"
								onChange={(e) => setEmail(e.target.value)}
								value={email}
							/>
						)}

						{resetStep === 1 && <ConfirmationCode setConfirm={setResetCode} />}

						{resetStep === 2 && (
							<input
								type="password"
								placeholder="New password"
								onChange={(e) => setPassword(e.target.value)}
								password={password}
							/>
						)}

						<div className="error-message">
							<b>{error.type}</b>
							{error.message}
						</div>

						<button className="submit" onClick={resetPassword}>
							{['Reset', 'Enter', 'Save'][resetStep]}
						</button>
						<div className="reset" onClick={() => setModalState("login")}>
							Back to login
						</div>

						<Loader show={isLoading} />
					</div>
				)}
				{modalState === "phone" && (
					<div className="form">
						<p className='phone-title'>Please enter your phone number</p>
						<div className="error-message">
							<b>{error.type}</b>
							{error.message}
						</div>
						<PhoneInput
							className='phone-input'
							country={'us'}
							specialLabel=''
							value={phoneNumber.toString()}
							onChange={(phone) => setPhoneNumber(phone)}
						/>
						<div className={classNames("timer-container", { show: timeLeft > 0 })}>
							<p className="timer-text">Resend available in:</p>
							<span className="timer">{timeLeft}s</span>
						</div>
						<>
							{isOTPSend ?
								<>
									<button className='submit send-code' onClick={numberSignIn} disabled={timeLeft > 0}>Resend code</button>
									<ConfirmationCode setConfirm={setPhoneCode} />
									<button className='submit' onClick={confirmNumberSignIn}>Confirm code</button>
								</>
								:
								<>
									<button className='submit send-code' onClick={numberSignIn} disabled={timeLeft > 0}>Send code</button>
								</>
							}
						</>
					</div>
				)}

				<img
					src={IconClose}
					className="close"
					onClick={hideModal}
					alt="Close"
				/>
			</div>
		</div>
	);
};

export default observer(Signin);
