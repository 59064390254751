import axios from 'axios';
import React, { useState, useEffect, useRef, useMemo, useCallback, useLayoutEffect } from 'react';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';

import StatusBar from './StatusBar';
import moment from 'moment';

const CalendarProvider = () => {
	const [jobs, setJobs] = useState([]);
	const [jobsOnDate, setJobsOnDate] = useState([]);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [registerDate, setRegisterDate] = useState();
	const jobsRef = useRef(null);

	const [maxDate] = useState(() => {
		const date = new Date();
		date.setDate(date.getDate() + 40);
		return date;
	});

	useEffect(() => {
		axios.get('/profile/bids').then(({ data }) => {
			setJobs(data);

			const filtered = data.filter(job => {
				if (Array.isArray(job.date) && job.date.length === 2) {
					const jobStart = new Date(job.date[0]).toDateString();
					const jobEnd = new Date(job.date[1]).toDateString();
					return jobStart === selectedDate.toDateString() || jobEnd === selectedDate.toDateString();
				}
				return new Date(job.date).toDateString() === selectedDate.toDateString();
			});
			setJobsOnDate(filtered);
		});

		axios.get('/providers/register-date').then(({ data }) => {
			setRegisterDate(data);
		});
	}, []);


	const onSelectDate = useCallback((date) => {
		const filtered = jobs.filter(job => {
			if (Array.isArray(job.date) && job.date.length === 2) {
				const jobStart = new Date(job.date[0]).toDateString();
				const jobEnd = new Date(job.date[1]).toDateString();
				return jobStart === date.toDateString() || jobEnd === date.toDateString();
			}
			return new Date(job.date).toDateString() === date.toDateString();
		});

		setJobsOnDate(filtered);
		setSelectedDate(date);
	}, [jobs]);

	const tileClassName = ({ date, view }) => {
		if (view !== 'month') return;

		return jobs.some(job => {
			if (Array.isArray(job.date) && job.date.length === 2) {
				const jobStart = new Date(job.date[0]).toDateString();
				const jobEnd = new Date(job.date[1]).toDateString();
				return jobStart === date.toDateString() || jobEnd === date.toDateString();
			}

			return new Date(job.date).toDateString() === date.toDateString();
		}) ? 'booked' : null;
	};


	useLayoutEffect(() => {
		if (jobsOnDate.length && jobsRef.current) {
			jobsRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [jobsOnDate])

	return (
		<div className='page calendar'>
			<StatusBar />
			<Calendar
				onChange={onSelectDate}
				value={selectedDate}
				maxDate={maxDate}
				minDate={new Date(registerDate?.registrationDate)}
				tileDisabled={({ date, view }) =>
					view === 'month' && (date < new Date(registerDate?.registrationDate) || date > maxDate)
				}
				tileClassName={tileClassName
				}
			/>
			<div className='jobs' ref={jobsRef}>
				<h2>Jobs on {selectedDate.toDateString()}:</h2>
				{jobsOnDate.length ? (
					jobsOnDate.map((job) => (
						<div className='job' key={job._id}>
							<div className="details">
								<h3><Link to={`/jobs/${job._id}`}>{job.title}</Link></h3>
								<p><span>Details: </span>{job.description}</p>
								<p><span>Price: </span>${job.price}</p>
							</div>

							<div className="status">
								<p><span>Job time: </span>{moment(job.time.startTime, "HH:mm").format("h:mm A")}-{moment(job.time.endTime, "HH:mm").format("h:mm A")}</p>
								<p><span>Status: </span>{job.status.replace('_', ' ')} {job.status === "active" && "(not funded)"}</p>
							</div>
						</div>
					))
				) : (
					<div className="error">No jobs booked at this time.</div>
				)}
			</div>
		</div>
	);
};

export default CalendarProvider;
