import axios from 'axios';
import BaseModal from 'components/Base/BaseModal';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store';
import React, { useEffect, useMemo, useState } from 'react'
import ConfirmationCode from 'components/ConfirmationCode/ConfirmationCode';
import { countdown, parseError } from 'utils/helpers';

const ConfirmPhone = ({ onConfirmed }) => {
    const [timeLeft, setTimeLeft] = useState(0)
    const [phoneCode, setPhoneCode] = useState('')
    const [showOtpModal, setShowOtpModal] = useState(false)
    const [error, setError] = useState({});

    const store = useStore();

    const isPhoneVerifed = useMemo(() => {
        return !store.auth.profile.stringsToFill.includes('isPhoneVerified')
    }, [store.auth.profile.stringsToFill])


    const validatePhoneNumber = async ({ resend }) => {
        try {
            setError({})
            await axios.post("/profile/phone/send-otp", {
                resend,
            });

            if (resend) {
                setTimeLeft(60)
                countdown(setTimeLeft)
            } else {
                setShowOtpModal(true);
            }
        } catch (error) {
            setError(parseError(error));
            console.error("Failed to send OTP:", error);
        }
    };

    const confirmPhoneValidateCode = async () => {
        try {
            await axios.post('/profile/phone/validate-otp', { code: phoneCode });
            setShowOtpModal(false);
            store.auth.updateStringsToFill("isPhoneVerified", false);
            onConfirmed();
        } catch (error) {
            setError(parseError(error));
            console.error("Validation failed", error);
        }
    };

    return (
        <div>
            <button
                className='btn phone-confirmation'
                onClick={validatePhoneNumber}
                disabled={isPhoneVerifed}
            >
                {isPhoneVerifed ? "Phone confirmed" : "Validate phone number"}
            </button>
            <BaseModal
                show={showOtpModal}
                onClose={() => setShowOtpModal(false)}
                className="center"
            >
                <div className="confirmation-container">
                    <h2>Verify Your Phone Number</h2>
                    <p>Please enter the confirmation code sent to your phone to validate your number.</p>
                    <ConfirmationCode
                        setConfirm={setPhoneCode}
                    >
                        {error.message ?
                            (<div className="error">
                                <b>{error.type}:</b> {error.message}
                            </div>)
                            :
                            (<div className="resend-container">
                                {timeLeft > 0 ? (
                                    <div className="timer">Resend available in {timeLeft} seconds.</div>
                                ) : (
                                    <div className="resend">
                                        Did not receive a code?
                                        <button onClick={() => validatePhoneNumber({ resend: true })}>
                                            Resend
                                        </button>
                                    </div>
                                )}
                            </div>)}
                    </ConfirmationCode>
                    <button onClick={confirmPhoneValidateCode} className="base-button">
                        Confirm
                    </button>
                </div>
            </BaseModal>
        </div>
    )
}

export default observer(ConfirmPhone)