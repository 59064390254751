import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import BaseTabs from 'components/Base/BaseTabs';

import IconMessage from './img/message.png';
import MessageModal from 'components/Modals/MessageModal';
import JobCancelModal from 'components/Modals/JobCancelModal';
import BaseModal from 'components/Base/BaseModal';
import ReviewModal from './ReviewModal';
import IconReview from './img/review.png';
import Completed from 'components/Base/Completed';

const MyBids = () => {
	const [tabs, setTabs] = useState([
		{ value: 'pending', name: 'Pending', count: 0 },
		{ value: 'working', name: 'Hired', count: 0 },
		{ value: 'canceled', name: 'Canceled', count: 0 },
		{ value: 'completed', name: 'Completed', count: 0 },
		{ value: 'dispute', name: 'Dispute', count: 0 }
	]);

	const [currentTab, setCurrentTab] = useState(tabs[0].value);
	const [jobs, setJobs] = useState([]);
	const [showMessageModal, setShowMessageModal] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showCompletionModal, setShowCompletionModal] = useState(false);
	const [showReviewModal, setShowReviewModal] = useState(false)
	const [messageModalCustomer, setMessageModalCustomer] = useState(null);
	const [jobId, setJobId] = useState(null);
	const [reviewJob, setReviewJob] = useState(null);

	const countJobs = (data) => {
		setTabs(prev => {
			return prev.map(item => {
				let pendingCount = data.filter(job => job.status === 'pending' || job.status === 'pending_acceptance' || job.status === 'pending_reschedule').length;
				if (item.value === 'pending') {
					item.count = pendingCount;
				} else if (item.value === 'canceled') {
					item.count = data.filter(job => job.status === 'canceled' || job.status === 'late_canceled').length;
				} else {
					item.count = data.filter(job => job.status === item.value).length;
				}
				return { ...item };
			});
		});
	}

	useEffect(() => {
		axios.get('/profile/bids').then(({ data }) => {
			setJobs(data);
			countJobs(data)
		});
	}, []);

	const cancelWeeklyJob = async (jobId) => {
		try {
			await axios.patch(`/jobs/${jobId}/repeat/disable`, { weekly_repeat: false });
			setJobs(prev => prev.map(job => {
				if (job._id === jobId) {
					return {
						...job,
						weekly_repeat: false
					};
				}
				return job;
			}));
		} catch (error) {
			console.error(error);
		}
	}

	const cancelJob = async () => {
		try {
			await axios.patch(`/jobs/${jobId}/cancel`);

			setJobs(prev => {
				const updatedJobs = prev.map(job => {
					if (job._id === jobId) {
						return {
							...job,
							status: 'canceled'
						};
					}
					return job;
				});
				countJobs(updatedJobs);
				return updatedJobs;
			});
		} catch (error) {
			console.error(error);
		}
	}

	const acceptJob = async (jobId) => {
		try {
			await axios.patch(`/jobs/${jobId}/accept`)
			setJobs(prev => {
				const updatedJobs = prev.map(job => {
					if (job._id === jobId) {
						return {
							...job,
							status: 'working',
							bid: {
								...job.bid,
								status: 'hired'
							}
						};
					}
					return job;
				});
				countJobs(updatedJobs);
				return updatedJobs;
			});
		} catch (error) {
			console.log(error)
		}
	}

	const acceptReschedule = async (jobId) => {
		try {
			await axios
				.patch(`/jobs/${jobId}/reschedule/accept`)
				.then(({ data }) => {
					setJobs(prev => {
						const updatedJobs = prev.map(job => {
							if (job._id === jobId) {
								return {
									...job,
									status: data.status
								}
							}
							return job;
						})
						countJobs(updatedJobs);
						return updatedJobs;
					})
				})
		} catch (error) {
			console.log(error)
		}
	}

	const requestCompletionJob = async () => {
		try {
			await axios.patch(`/jobs/${jobId}/complete/request`)
			setJobs(prev => prev.map(job => {
				if (job._id === jobId) {
					return {
						...job,
						timeout: moment().add(7, 'd')
					};
				}
				return job;
			}));
			setShowCompletionModal(false)
		} catch (error) {
			console.log(error)
		}
	}

	const tabJobs = useMemo(() => {
		if (currentTab === 'pending') {
			return jobs.filter((job) => job.status === 'pending' || job.status === 'pending_acceptance' || job.status === 'pending_reschedule');
		}

		if (currentTab === 'working') {
			return jobs.filter(
				(job) => job.status === 'working'
			);
		}

		if (currentTab === 'canceled') {
			return jobs.filter((job) => job.status === 'canceled' || job.status === 'late_canceled');
		}

		if (currentTab === 'completed') {
			return jobs.filter(
				(job) => job.status === 'completed'
			);
		}

		if (currentTab === "dispute") {
			return jobs.filter(
				(job) => job.status === "dispute"
			);
		}

	}, [jobs, currentTab]);

	return (
		<div className="page bids mybids">
			<h1>My Jobs</h1>
			<Completed show={completed} setShow={setCompleted} duration="750" />
			<BaseTabs
				tabs={tabs}
				onTab={setCurrentTab}
				current={currentTab}
				sticky
			>
				{!tabJobs.length && (
					<div className="empty">
						<span>{currentTab}</span> list is empty
					</div>
				)}

				{tabJobs.map((job) => (
					<div className="bid" key={job._id}>
						<div className="content">
							<Link className="title" to={`/jobs/${job._id}`}>
								{job.title}
							</Link>
							{job.status === "dispute" && <div className="dispute">This job is in dispute</div>}
							<div className="info">
								<div className="item">
									Customer:{' '}
									<b>
										{job.creator.name} {job.creator.surname}
									</b>
								</div>
								<div className="item">
									Date:
									<b>
										{job.date.length > 1 ?
											<>
												{moment(job.date[0]).format("DD MMM.")} - {moment(job?.date[1]).format('DD MMM. YYYY')}
												<br />
												{moment(job.time.startTime, "HH:mm").format("h:mm A")}-{moment(job.time.endTime, "HH:mm").format("h:mm A")}
											</>
											:
											<>
												{moment(job.date).format("DD MMM. YYYY")} {moment(job.time.startTime, "HH:mm").format("h:mm A")}-{moment(job.time.endTime, "HH:mm").format("h:mm A")}
											</>
										}
										{/* {moment(job.date).format("DD MMM. YYYY")} {job.time.startTime}-{job.time.endTime} */}
									</b>
								</div>
								<div className="item">
									Requested Price:
									<b>
										{job.bid.price
											? `Suggested $${job.bid.price}`
											: `Fixed $${job.price}`}
									</b>
								</div>
							</div>

							<div className="more">
								{/* <div className="message">{job.bid.message}</div> */}
								{job.status === 'pending_reschedule' &&
									<button
										className="btn bordered message"
									>
										Waiting for Reschedule
									</button>
								}

								{job.bid.status === 'hired' && (['working', 'pending', 'pending_acceptance'].includes(job.status)) && (
									<div className="buttons large">
										{job.status === 'pending_acceptance' &&
											<button
												className="btn bordered message"
												onClick={() => acceptReschedule(job._id)}
											>
												Accept new reschedule
											</button>
										}
										{job.status === 'pending' && (
											<button
												className="btn bordered message"
												onClick={() => {
													acceptJob(job._id)
													setJobId(job._id)
												}}
											>
												Accept job
											</button>
										)}
										{(job.status === 'working' && moment().isAfter(moment(`${job.date} ${job.time.startTime}`, 'YYYY-MM-DD HH:mm'))) && (
											<button
												className="btn bordered message"
												disabled={job.timeout}
												onClick={() => {
													setJobId(job._id);
													setShowCompletionModal(true)
												}}
											>
												{job.timeout ? "Marked as completed" : "Mark as completed"}
											</button>
										)}
										<button
											className="btn bordered message"
											disabled={job.status === "dispute"}
											onClick={() => {
												setShowCancelModal(true);
												setJobId(job._id);
												setMessageModalCustomer(job.creator);
											}}
										>
											Cancel job
										</button>
										{job.weekly_repeat !== null &&
											<button className="btn bordered message" disabled={!job.weekly_repeat} onClick={() => cancelWeeklyJob(job._id)}>
												{job.weekly_repeat ? 'Cancel weekly repeat' : 'Weekly repeat canceled'}
											</button>
										}
										<button
											className="btn bordered message"
											onClick={() => {
												setShowMessageModal(true);
												setMessageModalCustomer(job.creator);
											}}
										>
											<img src={IconMessage} alt="Message" />
											<span>Message</span>
										</button>
									</div>
								)}
								{job.status === 'completed' && (
									<button
										className="review"
										onClick={() => {
											setReviewJob(job);
											setShowReviewModal(true);
										}}
										disabled={!!job.review}
									>
										<img src={IconReview} alt="Review" title="Review" />
										<span>{!!job.review ? 'Reviewed' : 'Add review'}</span>
									</button>
								)}
							</div>
						</div>
					</div>
				))}
			</BaseTabs >
			<MessageModal
				show={showMessageModal}
				onClose={() => setShowMessageModal(false)}
				user={messageModalCustomer}
				onSubmit={() => cancelJob()}
			/>

			<JobCancelModal
				show={showCancelModal}
				onClose={() => setShowCancelModal(false)}
				user={messageModalCustomer}
				onSubmit={() => cancelJob()}
			/>
			<BaseModal
				show={showCompletionModal}
				onClose={() => setShowCompletionModal(false)}
				onSubmit={requestCompletionJob}
			>
				<h2>Mark this job as completed?</h2>
				<p>Once you marked this job as completed, payment will be released in 1 day unless the customer files a dispute.</p>
				<button onClick={requestCompletionJob} className="base-button">
					Yes, complete
				</button>
			</BaseModal>

			<ReviewModal
				show={showReviewModal}
				onClose={() => setShowReviewModal(false)}
				job={reviewJob}
				onCompleted={() => {
					setJobs(prev => prev.map(job => {
						if (job._id === reviewJob._id) {
							return {
								...job,
								review: true
							};
						}
						return job;
					}));
					setCompleted(true);
					setShowReviewModal(false)
				}}
			/>
		</div >
	);
};

export default MyBids;