import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAvailabilities, parseError } from 'utils/helpers';
import Completed from 'components/Base/Completed';
import TagsSelect from '../../components/TagsSelect/TagsSelect';
import {
	emptyErrorState,
	emptyTimeSlots,
} from 'components/AvailabilityInput/availability-input.constants';
import AvailabilityInput from 'components/AvailabilityInput/AvailabilityInput';
import moment from 'moment';
import DatePicker from 'components/DatePicker/DatePicker';
import Loader from 'components/Base/Loader';
import BaseModal from 'components/Base/BaseModal';
import PaypalButton from 'components/Paypal/PaypalButton';
import IconPaid from './img/paid.svg';
import IconInfo from './img/info.svg';
import IconMoon from './img/moon.png'

const JobCreateProvider = () => {
	const [tags, setTags] = useState([]);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [rate, setRate] = useState(0);
	const [price, setPrice] = useState(100);
	const [fee, setFee] = useState(20);
	const [cost, setCost] = useState([]);
	const [suggestPrice, setSuggestPrice] = useState(false);
	const [jobId, setJobId] = useState('')
	const [showModal, setShowModal] = useState(false);
	const [isPaid, setIsPaid] = useState(false);
	const [job, setJob] = useState({ title: '', price: 0 });
	const [showLoader, setShowLoader] = useState(false)
	const [location, setLocation] = useState({
		city: '',
		street: '',
		zip: '',
	});
	const [completed, setCompleted] = useState(false);
	const [error, setError] = useState({
		type: '',
		message: '',
	});
	const [timeData, setTimeData] = useState({
		date: [],
		startTime: '',
		endTime: '',
		weeklyRepeat: false,
	});
	const [overnight, setOvernight] = useState(false);
	const [isOvernightValid, setIsOvernightValid] = useState(false);
	const [providersOvernight, setProvidersOvernight] = useState(false)

	const [availabilitiesState, setAvailabilitiesState] =
		useState(emptyTimeSlots);
	const [availabilitiesErrorState, setAvailabilitiesErrorState] =
		useState(emptyErrorState);

	const [editProviderId, setEditProviderId] = useState('')

	const navigate = useNavigate();
	const { providerId } = useParams();
	const { id: editJobId } = useParams();

	useEffect(() => {
		if (editJobId) setJobId(editJobId);
	}, [editJobId])

	const checkOvernightAvailability = () => {
		const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

		for (let i = 0; i < days.length; i++) {
			const currentDay = days[i];
			const nextDay = days[(i + 1) % days.length];

			const hasEndAtMidnight = availabilitiesState[currentDay]?.some(slot => slot.endTime === "23:59");

			const hasStartAtMidnight = availabilitiesState[nextDay]?.some(slot => slot.startTime === "00:00");
			if (hasEndAtMidnight && hasStartAtMidnight) {
				return true;
			}
		}
		return false;
	};

	useEffect(() => {
		setIsOvernightValid(checkOvernightAvailability());
	}, [timeData, checkOvernightAvailability]);

	useEffect(() => {
		if (editJobId) {
			axios
				.get(`/jobs/${editJobId}`)
				.then(({ data }) => {
					const transformedTags = data.tags.map(item => [item]);
					setTags(transformedTags)
					setTitle(data.title)
					setDescription(data.description)
					setLocation(data.location)
					setEditProviderId(data.providerId)
					setRate(data.rate)
					setPrice(data.price)
					setTimeData({
						date: data.date,
						startTime: data.time.startTime,
						endTime: data.time.endTime,
						weeklyRepeat: data.weekly_repeat ? true : false
					})
					setOvernight(overnight)
				})
		}
	}, [editJobId])

	const onPaid = useCallback(
		(id) => {
			axios.patch(`/jobs/${jobId || editJobId}/paid`, { payment_id: id }).then(() => {
				setIsPaid(true);
			});
		},
		[jobId, editJobId]
	);

	const acceptBid = useCallback(() => {
		axios
			.patch(`/jobs/${jobId || editJobId}/bids/${job.bids[0]._id}/accept`)
			.then(() => {
				setCompleted(true);
				setShowModal(false);
				setShowLoader(false)
			})
	}, [jobId, job, editJobId]);


	const fetchBids = useCallback((jobId) => {
		axios
			.get(`/jobs/${jobId || editJobId}/bids`).then(({ data }) => {
				setJob(data)
				setShowModal(true)
			})
	}, [jobId, editJobId])


	const locationInput = useCallback((e) => {
		const { name, value } = e.target;
		setLocation((loc) => ({
			...loc,
			[name]: value,
		}));
	}, []);


	useEffect(() => {
		if (timeData.startTime && timeData.endTime && rate !== undefined) {
			const format = 'HH:mm';
			let startTime = moment(timeData.startTime, format);
			let endTime = moment(timeData.endTime, format);
			let hours = 0;

			if (overnight) {
				if (endTime.isBefore(startTime)) {
					endTime.add(1, 'day');
				}
			} else {
				if (endTime.isBefore(startTime)) {
					setPrice("0.00");
					setFee("0.00");
					return;
				}
			}

			const duration = moment.duration(endTime.diff(startTime));
			hours = duration.asHours();
			hours = Math.ceil(hours * 2) / 2;

			const priceWithoutFee = (hours * rate).toFixed(2);
			const totalPrice = (hours * rate * 1.2).toFixed(2);

			setFee((totalPrice - priceWithoutFee).toFixed(2));
			setPrice(totalPrice);
		}
	}, [timeData.startTime, timeData.endTime, rate, overnight]);


	const submitJob = useCallback(async () => {
		setError({ type: '', message: '' });
		setShowLoader(true);

		if (overnight && !isOvernightValid) {
			setError({ type: 'Overnight', message: 'jobs must span consecutive days with proper time slots.' });
			setShowLoader(false);
			return;
		}

		const body = {
			title,
			description,
			tags: tags.map((item) => item[0]),
			date: timeData.date,
			time: { startTime: timeData.startTime, endTime: timeData.endTime },
			price,
			suggest_price: suggestPrice,
			location,
			provider: providerId || editProviderId,
			weekly_repeat: timeData.weeklyRepeat || null,
			jobId: editJobId,
			isOvernight: overnight
		};

		const method = editJobId ? 'patch' : 'post';
		const url = editJobId ? `/jobs/${editJobId}` : '/jobs/jobandbid';

		await axios({ method, url, data: body })
			.then(({ data }) => {
				setJobId(data.jobId);
				fetchBids(data.jobId);
			})
			.catch((error) => {
				setShowLoader(false);
				setError(parseError(error));
			});
	}, [
		jobId,
		title,
		description,
		tags,
		price,
		suggestPrice,
		location,
		timeData,
		overnight,
		isOvernightValid,
		overnight,
		navigate,
	]);

	useEffect(() => {
		if (!providerId && !editProviderId) {
			return;
		}

		axios
			.get(`/profile/provider/${providerId || editProviderId}`)
			.then(({ data }) => {
				setCost(data.cost.map((item) => [item.title]));
				setRate(data.rate);
				if (data.availabilities) {
					setAvailabilitiesState(getAvailabilities(data))
				}
				if (data.overnight) setProvidersOvernight(data.overnight)
			})
			.catch((error) => {
				console.log(error)
			});
	}, [providerId, editProviderId]);

	return (
		<div className="page job-create">
			<BaseModal
				show={showModal}
				onClose={() => { setShowModal(false); setShowLoader(false) }}
				className="center accept"
			>
				<h2>Book this job?</h2>
				<p>
					<b>${job.price}</b> by {job.bids?.[0].provider.name} {' '} {job.bids?.[0].provider.surname}
				</p>

				{isPaid ? (
					<div className="paid">
						<img src={IconPaid} alt="Success paid" />
						<span>Successfully paid</span>
					</div>
				) : (
					<PaypalButton
						amount={price}
						payee={job?.bids?.[0].provider.paypal}
						type="paypal"
						onOrder={onPaid}
					/>
				)}

				<p className="small">
					In order to book a job, you must pay in advance for the
					work. The money will be transferred to the provider only after you
					mark the work as completed.
				</p>

				<button onClick={acceptBid} className="base-button" disabled={!isPaid}>
					Yes, accept
				</button>
			</BaseModal>

			<h1>{jobId ? 'Edit a Job' : 'Create a Job'}</h1>
			<div className="block">
				<h2>Details</h2>

				<label className="base-label">What do you need done?</label>
				<input
					type="text"
					className="base-input"
					value={title}
					onInput={(e) => setTitle(e.target.value)}
				/>

				<label className="base-label">Detailed message</label>
				<textarea
					className="base-input"
					rows="6"
					value={description}
					onInput={(e) => setDescription(e.target.value)}
				></textarea>
			</div>

			<div className="block">
				<h2>Location</h2>

				<div className="flex">
					<div className="w48">
						<label className="base-label mn">City</label>
						<input
							type="text"
							className="base-input"
							name="city"
							value={location.city}
							onInput={locationInput}
						/>
					</div>
					<div className="w48">
						<label className="base-label mn">Zip Code</label>
						<input
							type="text"
							className="base-input"
							name="zip"
							value={location.zip}
							onInput={locationInput}
						/>
					</div>
				</div>

				<label className="base-label">Street</label>
				<input
					type="text"
					className="base-input"
					name="street"
					value={location.street}
					onInput={locationInput}
				/>
			</div>
			<div className="block">
				<h2>Hourly Rate</h2>
				<label className="flex">
					<div className="hourly-rate">
						<span>${rate}</span>
						&nbsp;per hour
					</div>
				</label>
			</div>

			<div className="block tags">
				<h2>Tags</h2>
				<TagsSelect onChange={setTags} init={tags} cost={cost} />
			</div>

			<DatePicker
				providerId={providerId || editProviderId}
				availabilitiesState={availabilitiesState}
				timeData={timeData}
				setTimeData={setTimeData}
				overnight={overnight}
			/>

			<h2>Overnight</h2>
			<label className="base-checkbox overnights">
				<input
					type="checkbox"
					checked={overnight}
					onChange={(e) => setOvernight(e.target.checked)}
					disabled={!isOvernightValid || !providersOvernight}
				/>
				<div className="check"></div>
				<div className="base-label">
					{providersOvernight
						? "Only show the dates where overnight shifts are available"
						: "Provider doesn't work overnight"}
				</div>
			</label>

			<div className="base-label">Days that have overnight shifts available are marked with {<img className='icon' src={IconMoon} alt="icon_moon" />}</div>

			<div className="block availability">
				<AvailabilityInput
					availabilities={availabilitiesState}
					error={availabilitiesErrorState}
					canEdit={false}
				/>
			</div>

			<div className="block budget">
				<h2>Booking cost
					<div className='on-hover-tip'>
						<img src={IconInfo} className='on-hover-tip' alt="info" />
						<div className='text-popup'>
							We're committed to payment transparency. Many care service providers charge exorbitant fees, often taking 80% or more of the money they receive from customers before paying their caregivers. We're proud to offer our service with a much lower platform fee of 20% on all transactions. This fee helps us cover our administrative and operational costs and makes it possible for us to offer this service.
						</div>
					</div>
				</h2>

				<div className="base-label">
					Emissary is committed to payment transparency. Your total booking cost is your caregiver's hourly rate multiplied by the number of hours booked, plus a 20% platform services fee that makes it possbile for us to offer this service.
				</div>

				<div className="flex">
					<div className="title">Budget:</div>

					<div className="price">
						<span>$</span>
						<input
							disabled
							type="number"
							min="35"
							step="1"
							value={price}
							onInput={(e) => setPrice(e.target.value)}
						/>
					</div>
					<div>
						Booking: <b>${(price - fee).toFixed(2)}</b> + Fees: <b>${fee}</b>
					</div>
				</div>
			</div>

			<div className="error">
				<b>{error.type}</b>&nbsp;{error.message}
			</div>

			<button className="submit" onClick={submitJob}>
				Book Job
			</button>

			<Completed redirect="/jobs/my" show={completed} setShow={setCompleted} />
			<Loader show={showLoader} />
		</div>
	);
};

export default observer(JobCreateProvider);
