import axios from 'axios';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from '../../store';
import { currentUSStateCodes, replacementFields } from 'utils/constants';
import { generateCSV, parseError } from 'utils/helpers';

import Loader from 'components/Base/Loader';
import BaseEdit from 'components/Base/BaseEdit';
import Completed from 'components/Base/Completed';
import BaseCheckbox from 'components/Base/BaseCheckbox';
import ConfirmPhone from './ConfirmPhone';

const TabGeneral = () => {
	const avatarPrev = useRef();
	const avatarInput = useRef();
	const [avatarChanged, setAvatarChanged] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isSavedField, setIsSavedField] = useState({});
	const [error, setError] = useState({});
	const [generalInfo, setGeneralInfo] = useState({
		name: '',
		surname: '',
		location: {
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: '',
			zipCode: '',
			country: '',
			countryCode: '',
		},
		about: '',
		phone: '',
		autoCalendarEvent: false,
		isGoogleConnected: false,
		isPhoneVerified: false,
		isAddressVerified: false,
		emailMessageNotify: false,
		emailNotifications: false
	});
	const [prevGeneralInfo, setPrevGeneralInfo] = useState({});
	const store = useStore();
	const navigate = useNavigate();

	useEffect(() => {
		axios
			.get('/profile/general')
			.then(({ data }) => {
				setGeneralInfo(data);
				setPrevGeneralInfo(data);
			})
			.catch((error) => {
				console.error("Error fetching general profile info:", error);
			});
	}, []);

	// Only on first page loading
	useEffect(() => {
		if (store.auth.user.avatar && !avatarPrev.current) {
			avatarPrev.current = store.auth.user.avatar;
		}
	}, [store.auth.user.avatar]);

	// Only once on unmount
	useEffect(() => {
		return () => {
			store.auth.changeAvatar(avatarPrev.current);
		};
	}, [store.auth]);

	const onAvatarChange = useCallback(
		(event) => {
			if (event.target.files.length) {
				const file = event.target.files[0];
				const image = URL.createObjectURL(file);
				store.auth.changeAvatar(image);
				setAvatarChanged(true);
			}
		},
		[store.auth]
	);

	const saveAvatar = useCallback(() => {
		const file = avatarInput.current.files[0];
		const form = new FormData();
		form.append('avatar', file);

		setIsLoading(true);

		axios
			.patch('/profile/avatar', form)
			.then(({ data }) => {
				avatarPrev.current = data.avatar;
				avatarInput.current.value = '';
				store.auth.changeAvatar(data.avatar);
				setAvatarChanged(false);
				setIsSaved(true);
			})
			.catch((error) => {
				console.error("Error saving avatar:", error);
				setIsSaved(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [store.auth]);

	const createGoogleToken = useGoogleLogin({
		onSuccess: credentialResponse => {
			createToken(credentialResponse.code)
		},

		flow: 'auth-code',
		onError: (e) => console.log('Google error', e),
		cookiePolicy: 'single_host_origin',
		responseType: 'code',
		accessType: 'offline',
		scope: 'openid email profile https://www.googleapis.com/auth/calendar'
	});

	const createToken = (token) => {
		axios.post('/profile/connectcalendar', { token })
			.then((response) => {
				console.log("Token created successfully:", response.data);
			})
			.catch((error) => {
				console.error("Error creating token:", error);
			});
	}

	const toggleGoogleNotification = (e) => {
		setGeneralInfo(prevForm => ({
			...prevForm,
			autoCalendarEvent: e.target.checked,
		}));
		saveChanges("autoCalendarEvent", e.target.checked);
	};

	const toggleEmailMessages = (e) => {
		setGeneralInfo(prevForm => ({
			...prevForm,
			emailMessageNotify: e.target.checked
		}));
		saveChanges("emailMessageNotify", e.target.checked);
	}

	const toggleEmailNotifications = (e) => {
		setGeneralInfo(prevForm => ({
			...prevForm,
			emailNotifications: e.target.checked
		}));
		saveChanges("emailNotifications", e.target.checked);
	}

	const downloadCSV = () => {
		axios.get('/profile/jobhistory').then(({ data }) => {
			const output = generateCSV(data);
			const blob = new Blob([output], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.setAttribute('href', url);
			a.setAttribute('download', 'MyJobHistory.csv');
			a.click();
		}).catch((error) => {
			setError({ type: "error", message: "Failed to download CSV. Please try again later." })
		});
	};

	const compareChanges = (e) => {
		const { name, value } = e.target;
		if (prevGeneralInfo.location[name] === value || prevGeneralInfo[name] === value) return
		saveChanges(name, value);
	}

	const comparePhoneNumber = (e) => {
		setGeneralInfo(prevForm => ({
			...prevForm,
			isPhoneVerified: false
		}));
		if (prevGeneralInfo.phone === e.target.value) return
		saveChanges("phone", e.target.value.replace(/\D/g, ''));
	}

	const saveChanges = (name, value) => {
		setError({});
		setIsLoading(true);
		let field = name;
		let previousValue = prevGeneralInfo[name];
		if (generalInfo[name] === undefined) {
			field = `location.${name}`;
			previousValue = prevGeneralInfo.location[name];
			setGeneralInfo(prevForm => ({
				...prevForm,
				isAddressVerified: false
			}));
		}

		axios
			.patch('/profile/general', { field, value })
			.then(({ data }) => {
				if (field === "name" || field === "surname") {
					store.auth.changeNames(generalInfo.name, generalInfo.surname);
				}
				if (prevGeneralInfo[name] === undefined) {
					setPrevGeneralInfo((prev) => ({
						...prev,
						location: {
							...prev.location,
							[name]: value,
						},
					}));
				} else {
					setPrevGeneralInfo((prev) => ({
						...prev,
						[name]: value,
					}));
				}
				if (field.startsWith('location.')) {
					const locationField = field.split('.')[1];
					store.auth.updateStringsToFill(locationField, !value);
				} else {
					store.auth.updateStringsToFill(field, !value);
				}

				setIsSavedField({ [field]: true });;
				checkProfileCompletion();

				setTimeout(() => {
					setIsSavedField({});
				}, 900);
			}).catch((error) => {
				setError(parseError(error));
				console.log(error);
				if (generalInfo[name] === undefined) {
					setGeneralInfo(prevForm => ({
						...prevForm,
						location: { ...prevForm.location, [name]: previousValue },
					}));
				} else {
					setGeneralInfo(prevForm => ({
						...prevForm,
						[name]: previousValue
					}));
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	const verifyAddress = () => {
		axios
			.patch('/profile/verify/address')
			.then(({ data }) => {
				setGeneralInfo(prevForm => ({
					...prevForm,
					isAddressVerified: true
				}))

				store.auth.updateStringsToFill("isAddressVerified", false)
				checkProfileCompletion()
			}).catch((error) => {
				setError({
					type: error.response.data.error,
					message: error.response.data.fields.map(word => replacementFields[word || word]).join(', ')
				});
				console.log(error.response);
			});
	}


	const checkProfileCompletion = useCallback(async () => {
		const pendingBooking = sessionStorage.getItem('pendingBooking');
		await store.auth.fetchUserInfo();

		if (pendingBooking && !store.auth.profile.stringsToFill.length) {
			const providerId = pendingBooking;
			sessionStorage.removeItem('pendingBooking');
			navigate(`/jobs/create/${providerId}`);
		}
	}, [store.auth.profile.stringsToFill, navigate]);

	return (
		<div className="edit-tab general">
			{!!store.auth.profile.stringsToFill.length && (
				<div className="profile-incomplete-notice">
					Please complete your profile to book jobs:
					<ul>
						{store.auth.profile.stringsToFill.map((field, index) => (
							<li key={index}>
								{replacementFields[field] || field}
							</li>
						))}
					</ul>
				</div>
			)}
			<div className="base-label">Avatar</div>
			<div className="avatar">
				<input
					type="file"
					className="base-input"
					ref={avatarInput}
					onChange={onAvatarChange}
				/>
				<button
					className="base-button upload"
					disabled={!avatarChanged}
					onClick={saveAvatar}
				>
					Upload
				</button>
			</div>
			<div className="flex">
				<div className="w48">
					<div className='input-container'>
						<div className="base-label">Name</div>
						<input
							type="text"
							className={classNames('base-input', { saved: isSavedField.name })}
							name="name"
							value={generalInfo.name}
							onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, name: e.target.value }))}
							onBlur={compareChanges}
						/>
						<span
							className={classNames("saved-message", { show: isSavedField.name })}
						>
							Saved
						</span>
					</div>
				</div>
				<div className="w48">
					<div className="input-container">
						<div className="base-label">Surname</div>
						<input
							type="text"
							className={classNames('base-input', { saved: isSavedField.surname })}
							name='surname'
							value={generalInfo.surname}
							onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, surname: e.target.value }))}
							onBlur={compareChanges}
						/>
						<span
							className={classNames("saved-message", { show: isSavedField.surname })}
						>
							Saved
						</span>
					</div>
				</div>
			</div>
			<div className='input-container'>
				<div className="base-label" >Phone number</div>
				<div className="phone-container">
					<PhoneInput
						className={classNames('phone-input', { save: isSavedField.phone })}
						country={'us'}
						specialLabel=''
						value={generalInfo.phone?.toString()}
						onChange={(phone) => setGeneralInfo(prevForm => ({ ...prevForm, phone: phone }))}
						onBlur={comparePhoneNumber}
					/>
					<ConfirmPhone onConfirmed={checkProfileCompletion} />
				</div>
				<span
					className={classNames("saved-message", { show: isSavedField['phone'] })}
				>
					Saved
				</span>
			</div>

			<div className={classNames('input-container', { saved: isSavedField.about })}>
				<div className="base-label">About me</div>
				<BaseEdit
					name={'about'}
					value={generalInfo.about}
					onChange={(about) => setGeneralInfo(prevForm => ({ ...prevForm, about: about }))}
					onBlur={compareChanges}
				/>
				<span
					className={classNames("saved-message", { show: isSavedField.about })}
				>
					Saved
				</span>
			</div>

			{store.auth.user.type === 'customer' && (
				<p className="after-about">
					Your profile is your way of presenting yourself to potential caregivers
					that are going to work with you. This will help ensure you find the
					right match for your needs.
				</p>
			)}

			<div className="divider" />

			<div className='input-container'>
				<div className="base-label">Address Line 1</div>
				<input
					type="text"
					className={classNames('base-input', { saved: isSavedField['location.addressLine1'] })}
					name='addressLine1'
					value={generalInfo.location.addressLine1}
					onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, addressLine1: e.target.value } }))}
					onBlur={compareChanges}
				/>
				<span
					className={classNames("saved-message", { show: isSavedField['location.addressLine1'] })}
				>
					Saved
				</span>
			</div>

			<div className='input-container'>
				<div className="base-label">Address Line 2</div>
				<input
					type="text"
					className={classNames('base-input', { saved: isSavedField['location.addressLine2'] })}
					name='addressLine2'
					value={generalInfo.location.addressLine2}
					onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, addressLine2: e.target.value } }))}
					onBlur={compareChanges}
				/>
				<span
					className={classNames("saved-message", { show: isSavedField['location.addressLine2'] })}
				>
					Saved
				</span>
			</div>

			<div className="flex">
				<div className='w24'>
					<div className='input-container'>
						<div className="base-label">City</div>
						<input
							type="text"
							className={classNames('base-input', { saved: isSavedField['location.city'] })}
							name='city'
							value={generalInfo.location.city}
							onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, city: e.target.value } }))}
							onBlur={compareChanges}
						/>
						<span
							className={classNames("saved-message", { show: isSavedField['location.city'] })}
						>
							Saved
						</span>
					</div>
				</div>

				<div className='w24'>
					<div className='input-container'>
						<div className="base-label">State</div>
						<select
							id="state"
							name="state"
							className={classNames('base-input', { saved: isSavedField['location.state'] })}
							value={generalInfo.location.state}
							onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, state: e.target.value } }))}
							onBlur={compareChanges}
						>
							<option value=""> -- Select a state --</option>
							{
								Object.entries(currentUSStateCodes).map(([stateCode, stateName]) => (
									<option key={stateCode} value={stateCode}>{stateName}</option>
								))
							}
						</select>
						<span
							className={classNames("saved-message", { show: isSavedField['location.state'] })}
						>
							Saved
						</span>
					</div>
				</div>

				<div className='w24'>
					<div className='input-container'>
						<div className="base-label">Zip Code</div>
						<input
							type="text"
							className={classNames('base-input', { saved: isSavedField['location.zipCode'] })}
							name="zipCode"
							value={generalInfo.location.zipCode}
							onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, zipCode: e.target.value } }))}
							onBlur={compareChanges}
						/>
						<span
							className={classNames('saved-message', { show: isSavedField['location.zipCode'] })}
						>
							Saved
						</span>
					</div>
				</div>
				<button
					className='btn address-confirmation'
					disabled={(!['addressLine1', 'city', 'state', 'zipCode', 'country'].every(key => generalInfo.location[key]) || generalInfo.isAddressVerified)}
					onClick={verifyAddress}
				>
					{generalInfo.isAddressVerified ? "Address confirmed" : "Validate address"}
				</button>
			</div>

			<div className="divider" />

			{/* <div className="flex"> */}
			<div className="base-label">Email notifications</div>
			<div className="w48">
				<div className="input-container">
					<BaseCheckbox
						onChange={toggleEmailNotifications}
						checked={generalInfo.emailNotifications}
						text={"Receive Job-Related Email Notifications"}
					/>
					<span
						className={classNames("saved-message", { show: isSavedField.emailNotifications })}
					>
						Saved
					</span>
				</div>
			</div>

			<div className="w48">
				<div className="input-container email-message">
					<BaseCheckbox
						onChange={toggleEmailMessages}
						checked={generalInfo.emailMessageNotify}
						text={"Receive Email Notifications for New Messages"}
					/>
					<span
						className={classNames("saved-message", { show: isSavedField.emailMessageNotify })}
					>
						Saved
					</span>
				</div>
			</div>
			{/* </div> */}

			<div className="divider" />
			<div className="base-label">Sync google calendar</div>
			{!generalInfo.isGoogleConnected &&
				<>
					<button className='btn' onClick={createGoogleToken}>Connect Google account</button>
					<br />
				</>
			}

			<div className='google-event'>
				{generalInfo.autoCalendarEvent !== undefined &&
					(<BaseCheckbox
						onChange={toggleGoogleNotification}
						checked={generalInfo.autoCalendarEvent}
						text={"Google calendar notifications"}
					>
					</BaseCheckbox>
					)}
			</div>

			<button className='base-button csv' onClick={downloadCSV}>Download job history (CSV)</button>

			{/* <div className='input-container save'> */}
			<div className="bottom">
				{error.message && (
					<div className="error">
						<b>{error.type}:</b> {error.message}
					</div>
				)}
			</div>
			<Loader show={isLoading} />
			<Completed show={isSaved} setShow={setIsSaved} />
		</div>
	);
};

export default observer(TabGeneral);
