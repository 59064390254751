import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { getJobTimes } from 'utils/helpers';

import BaseTabs from 'components/Base/BaseTabs';
import Review from './Review';

import IconClock from 'pages/Home/img/clock.png';
import IconLock from 'pages/Home/img/lock.svg';
import IconUnlock from 'pages/Home/img/unlock.svg';
import IconLocation from 'pages/Home/img/location.svg';

const Tabs = [
	{ value: 'Active Jobs' },
	{ value: 'Reviews' },
];

const CustomerDetails = ({ user }) => {
	const [tab, setTab] = useState(Tabs[0].value);

	return (
		<div className="about">
			<BaseTabs tabs={Tabs} current={tab} onTab={setTab}>
				{tab === Tabs[0].value && (
					<div className="list">
						{!user.jobs.length && <div className="empty">Nothing here yet</div>}

						{user.jobs?.map((job) => (
							<Link className="item" to={`/jobs/${job._id}`} key={job._id}>
								<div className="info">
									<div className="left">
										<div className="title">{job.title}</div>
										<div className="subtitle">
											<div className="location">
												<img src={IconLocation} alt="Location" />
												<span>{job.location.city}</span>
											</div>
											<div className="date">
												<img src={IconClock} alt="Time" />
												<span>
													{job.date.length > 1 ?
														<>
															{moment(job.date[0]).format("DD MMM.")} - {moment(job?.date[1]).format('DD MMM. YYYY')}
														</>
														:
														<>
															{moment(job.date).format("DD MMM. YYYY")}
														</>
													}
													<span className="full">
														, {moment(job.time.startTime, "HH:mm").format("h:mm A")} - {moment(job.time.endTime, "HH:mm").format("h:mm A")}
													</span>
												</span>
											</div>
										</div>
									</div>

									<div className="right">
										<img
											src={job.suggest_price ? IconUnlock : IconLock}
											className={classNames('lock', {
												unlock: job.suggest_price,
											})}
											alt="Suggested price"
										/>
										<div className="price">${job.price}</div>
									</div>
								</div>

								<div className="description">{job.description}</div>
							</Link>
						))}
					</div>
				)}

				{tab === Tabs[1].value && (
					<div className="list reviews">
						{!user.reviews.length && (
							<div className="empty">Nothing here yet</div>
						)}

						{user.reviews.map((review) => (
							<Review data={review} key={review._id} />
						))}

						{/* <Review rate={3} job={"Test review 1"} creator={user} text={"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet illo, velit ipsa eaque ad deleniti minus neque repellat laudantium beatae voluptatum"} />

						<Review rate={4.5} job={"Test review 2"} creator={user} text={"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet illo, velit ipsa eaque ad deleniti minus neque repellat laudantium beatae voluptatum, porro cum iusto doloremque quibusdam. Amet in ex illum. Eveniet illo, velit ipsa eaque ad deleniti minus neque repellat laudantium beatae voluptatum."} />

						<Review rate={2.2} job={"Test review 3"} creator={user} text={"Lorem ipsum dolor sit amet consectetur, adipisicing elit."} /> */}
					</div>
				)}
			</BaseTabs>
		</div>
	);
};

export default CustomerDetails;
