import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Categories, replacementFields } from 'utils/constants';
import { getAvailabilities, parseError } from 'utils/helpers';

import BaseEdit from 'components/Base/BaseEdit';
import Completed from 'components/Base/Completed';
import Dropdown from 'components/Base/Dropdown';

import IconCategory from './img/category.svg';
import IconRemove from './img/delete.png';
import AvailabilityInput from 'components/AvailabilityInput/AvailabilityInput';
import {
	emptyErrorState,
	emptyTimeSlots,
} from 'components/AvailabilityInput/availability-input.constants';
import PaypalConnectButton from 'components/Paypal/PaypalConnectButton';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import BaseModal from 'components/Base/BaseModal';

const TabProvider = () => {
	const [primaryLang, setPrimaryLang] = useState('');
	const [secondaryLang, setSecondaryLang] = useState('');
	const [resume, setResume] = useState('');
	const [cost, setCost] = useState([]);
	const [saved, setSaved] = useState(false);
	const [rate, setRate] = useState('');
	const [status, setStatus] = useState('')
	const [error, setError] = useState({});
	const [overnight, setOvernight] = useState(false);
	const [isOvernightValid, setIsOvernightValid] = useState(false);
	const [availabilitiesState, setAvailabilitiesState] =
		useState(emptyTimeSlots);
	const [originalAvailabilitiesState, setOriginalAvailabilitiesState] =
		useState();
	const [availabilitiesErrorState, setAvailabilitiesErrorState] =
		useState(emptyErrorState);
	const restCosts = useMemo(() => {
		return Categories.filter((cat) => !cost.find((item) => item.title === cat));
	}, [cost]);
	const [isSavedField, setIsSavedField] = useState({});
	const [prevForm, setPrevForm] = useState({});
	const [showModal, setShowModal] = useState(false)

	const store = useStore();

	useEffect(() => {
		axios
			.get('/profile/workinfo')
			.then(({ data }) => {
				if (data.resume) setResume(data.resume);
				if (data.cost) setCost(data.cost);
				if (data.rate) setRate(data.rate);
				if (data.isVisible) setStatus(data.isVisible);
				if (data.languages?.primary) setPrimaryLang(data.languages.primary);
				if (data.languages?.secondary) setSecondaryLang(data.languages.secondary);
				if (data.availabilities) {
					const availabilities = getAvailabilities(data)
					setAvailabilitiesState(availabilities)
					const originalAvailabilities = structuredClone(availabilities)
					setOriginalAvailabilitiesState(originalAvailabilities);
				}
				if (data.overnight) setOvernight(data.overnight)

				setPrevForm(data);
			})
			.catch((error) => {
				setError(parseError(error));
			});
	}, []);

	const addTag = useCallback((tag) => {
		setCost((prev) => [...prev, { title: tag }]);
		const newCost = [...cost, { title: tag }];
		saveChanges('cost', newCost)
	}, [cost]);

	const removeTag = useCallback((tag) => {
		setCost((prev) => prev.filter((c) => c.title !== tag));
		const newCost = cost.filter((c) => c.title !== tag);
		saveChanges('cost', newCost)
	}, [cost]);

	const toggleStatus = () => {
		axios.patch('/profile/changevisibility')
			.then(({ data }) => {
				setStatus(data.isVisible);
			})
			.catch((error) => {
				setError(parseError(error));
			});
	};

	/**
	 * Method to determine if a time slot overlaps with any existing time slots
	 * @param timeSlot
	 * @param existingTimeSlots
	 * @returns {boolean}
	 */
	const doesTimeSlotOverlap = useCallback((timeSlot, existingTimeSlots) => {
		let doesOverlapExist = false;
		const { startTime, endTime } = timeSlot;
		existingTimeSlots.forEach((existingTimeSlot) => {
			const { startTime: existingStartTime, endTime: existingEndTime } =
				existingTimeSlot;
			if (
				(startTime >= existingStartTime && startTime < existingEndTime) ||
				(endTime > existingStartTime && endTime <= existingEndTime) ||
				(startTime <= existingStartTime && endTime >= existingEndTime)
			) {
				// Overlap detected
				doesOverlapExist = true;
			}
		});
		return doesOverlapExist;
	}, []);

	const onAddAvailability = useCallback(
		(day, timeSlot) => {
			const updatedAvailabilities = { ...availabilitiesState };
			updatedAvailabilities[day].push(timeSlot);
			setAvailabilitiesState(updatedAvailabilities);
		},
		[availabilitiesState]
	);

	const onEditAvailabilities = useCallback((newAvailabilities) => {
		// setAvailabilitiesState(newAvailabilities);
		setAvailabilitiesState((prevAvailabilities) => {
			const updatedAvailabilities = { ...prevAvailabilities };
			Object.keys(newAvailabilities).forEach((day) => {
				updatedAvailabilities[day] = newAvailabilities[day].map((timeSlot) => ({
					...timeSlot,
				}));
			});
			return updatedAvailabilities;
		});
	}, []);

	const onSaveAvailabilities = useCallback(() => {
		const availabilities = [];
		let hasError = false;
		let errorMessage = '';

		Object.keys(availabilitiesState).forEach((day) => {
			availabilitiesState[day].forEach((timeSlot) => {
				const otherAvailabilities = availabilitiesState[day].filter(
					(availability) => availability._id !== timeSlot?._id
				);

				if (timeSlot.startTime >= timeSlot.endTime) {
					errorMessage = `Start time must be before end time on ${day}.`;
					hasError = true;
				} else if (doesTimeSlotOverlap(timeSlot, otherAvailabilities)) {
					errorMessage = `Time slots overlap on ${day}.`;
					hasError = true;
				} else {
					availabilities.push({
						dayOfWeek: day,
						startTime: timeSlot.startTime,
						endTime: timeSlot.endTime,
					});
				}
			});
		});

		if (hasError) {
			setAvailabilitiesErrorState({
				hasError,
				message: errorMessage,
			});
			return false;
		} else {
			axios
				.patch('/profile/availability', {
					availabilities,
				})
				.then(() => {
					setOriginalAvailabilitiesState(availabilitiesState);
					setSaved(true);
				})
				.catch((error) => {
					setError(parseError(error));
				});
		}
		return true;
	}, [availabilitiesState, doesTimeSlotOverlap]);

	const compareChanges = (e) => {
		const { name, value } = e.target;
		if (prevForm.languages[name] === value || prevForm[name] === value) return
		saveChanges(name, value);
	}


	const saveChanges = async (name, value) => {
		let field = name;
		if (field === "primary" || field === "secondary") {
			field = `languages.${field}`;
		}

		setError({});
		try {
			axios.
				patch('/profile/workinfo', { field, value })

			if (field !== `languages.secondary`) {
				// console.log(field, !value, 'send field')
				store.auth.updateStringsToFill(field, !value);
				await store.auth.fetchUserInfo();
			}
			setIsSavedField({ [field]: true })
			const timer = setTimeout(() => {
				setIsSavedField({});
			}, 900);
			return () => clearTimeout(timer);
		} catch (error) {
			setError(parseError(error));
		};
	}

	const checkOvernightAvailability = useCallback(() => {
		const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

		for (let i = 0; i < days.length; i++) {
			const currentDay = days[i];
			const nextDay = days[(i + 1) % days.length];
			const hasEndAtMidnight = availabilitiesState[currentDay]?.some(slot => slot.endTime === "23:59");
			const hasStartAtMidnight = availabilitiesState[nextDay]?.some(slot => slot.startTime === "00:00");

			if (hasEndAtMidnight && hasStartAtMidnight) {
				return true;
			}
		}
		return false;
	}, [availabilitiesState]);

	useEffect(() => {
		setIsOvernightValid(checkOvernightAvailability());
	}, [checkOvernightAvailability]);


	return (
		<div className="edit-tab work">
			<BaseModal
				show={showModal}
				onClose={() => setShowModal(false)}
			>
				<h2>Change profile visibility</h2>
				{status
					? <p>Your profile will be no longer visible to customers</p>
					: <p>Customers will be able to view your profile</p>
				}
				<button className='base-button' onClick={() => {

					toggleStatus();
					setShowModal(false);
				}}>
					Confirm
				</button>
			</BaseModal>

			{!!store.auth.profile.stringsToFill.length && (
				<div className="profile-incomplete-notice">
					Please complete your profile to book jobs:
					<ul>
						{store.auth.profile.stringsToFill.map((field, index) => (
							<li key={index}>
								{replacementFields[field] || field}
							</li>
						))}
					</ul>
				</div>
			)}

			<div className={classNames('input-container', { saved: isSavedField.resume })}>
				<div className="base-label">Experience and skills</div>
				<BaseEdit
					value={resume}
					name={'resume'}
					onChange={setResume}
					placeholder="Please provide any relevant information"
					maxLength={2000}
					rows={10}
					onBlur={(e) => compareChanges(e)}
				/>
				<span
					className={classNames('saved-message', { show: isSavedField.resume })}
				>
					Saved
				</span>
			</div>

			<div className="flex">
				<div className="w48">
					<div className='input-container'>

						<div className="base-label">Primary language</div>
						<input
							type="text"
							className={classNames('base-input', { saved: isSavedField['languages.primary'] })}
							value={primaryLang}
							name='primary'
							onInput={(e) => setPrimaryLang(e.target.value)}
							onBlur={(e) => compareChanges(e)}
						/>
						<span
							className={classNames('saved-message', { show: isSavedField['languages.primary'] })}
						>
							Saved
						</span>
					</div>
				</div>

				<div className="w48">
					<div className='input-container'>
						<div className="base-label">Secondary language</div>
						<input
							type="text"
							className={classNames('base-input', { saved: isSavedField['languages.secondary'] })}
							value={secondaryLang}
							name='secondary'
							onInput={(e) => setSecondaryLang(e.target.value)}
							onBlur={(e) => compareChanges(e)}
						/>
						<span
							className={classNames('saved-message', { show: isSavedField['languages.secondary'] })}
						>
							Saved
						</span>
					</div>
				</div>
			</div>

			{store.auth.user.isProvider &&
				(
					store.auth.user.paypal ?
						<>
							<div className="category-title">
								<span>PayPal</span>
							</div>
							<div className="base-label">
								PayPal already connected
							</div>
						</>
						:
						<>
							<div className="category-title">
								<span>PayPal</span>
							</div>
							<div className="base-label">
								Required to complete your profile
							</div>
							<PaypalConnectButton />
						</>
				)
			}

			{/* TODO - MAKE HOURLY RATES ONLY CORRESPOND TO JOB CATEGORIES */}
			{/* <div className="category-title">
				<span>Hourly Rates</span>
				<button className="add base-button" onClick={addCost}>
					Add Rate
				</button>
			</div> */}
			<div className='input-container'>
				<div className="category-title">
					<span>My hourly rate</span>
				</div>
				<div className="rate">
					<input
						type="number"
						min="0"
						max="100000"
						step="1"
						className={classNames('base-input', { saved: isSavedField['rate'] })}
						value={rate}
						name='rate'
						onChange={e => setRate(e.target.value)}
						onBlur={(e) => compareChanges(e)}
					/>
					<span>$/h</span>
				</div>
				<span
					className={classNames('saved-message', { show: isSavedField['rate'] })}
				>
					Saved
				</span>
			</div>

			<AvailabilityInput
				availabilities={availabilitiesState}
				originalAvailabilities={originalAvailabilitiesState}
				setOriginalAvailabilitiesState={setOriginalAvailabilitiesState}
				onAddAvailability={onAddAvailability}
				onEditAvailabilities={onEditAvailabilities}
				onSaveAvailabilities={onSaveAvailabilities}
				doesTimeSlotOverlap={doesTimeSlotOverlap}
				error={availabilitiesErrorState}
				setError={setAvailabilitiesErrorState}
				canEdit={true}
			/>
			<p>
				Each time slot is reserved for one job only, so multiple jobs can't be booked for the same time.
			</p>
			<div className='input-container'>
				<div className="category-title">
					<span>Overnihgt</span>
				</div>
				<div className="overnight-container">
					<input
						type="checkbox"
						name="overnight"
						checked={overnight}
						onChange={(e) => {
							setOvernight(e.target.checked);
							const modifiedEvent = {
								...e,
								target: {
									...e.target,
									name: 'overnight',
									value: e.target.checked,
								},
							};

							compareChanges(modifiedEvent);

						}}
						disabled={!isOvernightValid}
					/>

					<div className="base-label">Enable Overnight Job</div>
				</div>
				If you're working overnight, make sure to set your end time as 11:59 PM for the current day and start time as 12:00 AM for the next day.
				<span
					className={classNames('saved-message', { show: isSavedField['overnight'] })}
				>
					Saved
				</span>
			</div>

			<div className='input-container'>
				<div className="category-title">
					<span>Category</span>

					<Dropdown autoclose items={restCosts} onChange={addTag}>
						<button className="add base-button">Add category</button>
					</Dropdown>
				</div>
				<div className="list">
					{!cost.length && (
						<div className="length">You must select at least one category</div>
					)}

					{cost.map(({ title, price }, index) => (
						<div className="item category" key={title}>
							<div className="title base-input">
								<img src={IconCategory} alt="Category" />
								<span>{title}</span>
							</div>

							<div className="remove base-button" onClick={() => removeTag(title)}>
								<img src={IconRemove} alt="Remove" />
							</div>
						</div>
					))}
				</div>
				<span
					className={classNames('saved-message', { show: isSavedField['cost'] })}
				>
					Saved
				</span>
			</div>
			<div className='input-container'>
				<div className="category-title">
					<span>Profile visibility</span>
				</div>
				<p>Your profile is currently {status ? <span className='visible'>visible</span> : <span className='hidden'>hidden</span>}</p>
				<button className='base-button btn' onClick={() => setShowModal(true)}>{status ? 'Hide' : 'Show'}</button>
				<p>You must cancel all your active/penging jobs before pause your account.</p>
			</div>
			<div className="bottom">
				{error.message && (
					<div className="error">
						<b>{error.type}:</b> {error.message}
					</div>
				)}
			</div>

			<Completed show={saved} setShow={setSaved} />
		</div>
	);
};

export default observer(TabProvider);
