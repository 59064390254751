import { useMemo, useState } from 'react';
import BaseTabs from 'components/Base/BaseTabs';

import Review from './Review';
import moment from 'moment';

const Tabs = [
	{ value: 'Main' },
	{ value: 'Reviews' },
]

const ProviderDetails = ({ user }) => {
	const [tab, setTab] = useState(Tabs[0].value);

	const formatAvailabilities = useMemo(() => {
		if (!user.availabilities) return [];

		const grouped = user.availabilities.reduce((acc, day) => {
			if (!acc[day.dayOfWeek]) {
				acc[day.dayOfWeek] = [];
			}
			acc[day.dayOfWeek].push(`${moment(day.startTime, "HH:mm").format("h:mm A")}-${moment(day.endTime, "HH:mm").format("h:mm A")}`);
			return acc;
		}, {});
		Object.keys(grouped).forEach(day => {
			grouped[day].sort((a, b) => {
				const timeA = a.split('-')[0];
				const timeB = b.split('-')[0];
				return timeA.localeCompare(timeB);
			});
		});

		return Object.entries(grouped);
	}, [user.availabilities]);

	return (
		<div className="about">
			<BaseTabs tabs={Tabs} current={tab} onTab={setTab}>
				{tab === Tabs[0].value && (
					<div className="resume">
						<h2>Resume</h2>
						<p>{user.resume || 'Nothing here yet'}</p>

						<h2>Languages</h2>
						<p>
							Primary: <b>{user.languages.primary}</b>
							{user.languages.secondary && (
								<>
									<br />
									Secondary: <b>{user.languages.secondary}</b>
								</>
							)}
						</p>
						<h2>Availability</h2>

						{user.availabilities &&
							formatAvailabilities.map(([dayOfWeek, times], index) => (
								<div key={index} className="availability-day">
									<div className="day-name">{dayOfWeek}:</div>
									{times.map((time, timeIndex) => (
										<div key={timeIndex} className="time-slot">
											{time}
										</div>
									))}
								</div>
							))}
						{user.cost?.length > 0 && (
							<>
								<h2>Services</h2>
								{user.cost?.map((cost, index) => (
									<div className="cost" key={index}>
										<div className="title">{cost.title}</div>
										<div className="dots">&nbsp;</div>
									</div>
								))}
							</>
						)}
					</div>
				)}

				{tab === Tabs[1].value && (
					<div className="list reviews">
						{!user.reviews.length && (
							<div className="empty">Nothing here yet</div>
						)}

						{user.reviews.map((review) => (
							<Review data={review} key={review._id} />
						))}
					</div>
				)}
			</BaseTabs>
		</div>
	);
};

export default ProviderDetails;
